import Link from 'next/link';
import { ImageWithFallback } from '#/common/components/ImageWithFallback';
import { Card, CardContent } from '#/common/components/ui/card';
import type { GenericProduct } from '#/common/types/generic-product';
import { useIsProductFaved } from '../hooks/useIsProductFaved';
import { CartButton } from './Button/CartButton';
import { FavoriteButton } from './Button/FavoriteButton';
import { Icon } from './Icon';

interface Props {
  product: GenericProduct;
}

export function ProductCard({ product }: Props) {
  const { isFaved } = useIsProductFaved(product.product_id);

  return (
    <Card className="relative flex flex-1 flex-col">
      <Link
        href={`/catalogo/produto/${product.product_id}`}
        prefetch={false}
        className="relative overflow-hidden rounded-md"
      >
        <ImageWithFallback
          className="object-cover duration-300 hover:scale-110"
          src={product.image.src}
          alt={product.image.alt}
          productName={product.title}
          width={400}
          height={400}
        />
      </Link>
      <CardContent className="relative flex flex-1 flex-col justify-between gap-2 px-2 pt-2 pb-3 sm:px-3 sm:pb-4 xl:px-4 xl:pb-5">
        <div>
          <div className="mt-1 flex items-center justify-between">
            <Link
              href={`/catalogo/produto/${product.product_id}`}
              className="flex flex-1 flex-col justify-between"
              prefetch={false}
            >
              <p className="line-clamp-1 text-gray-500 text-xs md:text-sm dark:text-gray-300">
                Ref.: <span>{product.sku}</span>
              </p>
            </Link>

            <FavoriteButton
              variant="link"
              size="xs"
              aria-label="Adicionar aos Favoritos"
              className="h-5 w-5 cursor-pointer"
              title="Adicionar/remover item dos favoritos"
              product={{
                id: product.product_id,
                title: product.title,
              }}
            >
              <Icon
                name="heart"
                fill={isFaved ? '#AE1857' : 'none'}
                size={20}
              />
            </FavoriteButton>
          </div>
          <h3 className="mb-0 line-clamp-1 font-semibold text-[15px] md:text-base">
            {product.title}
          </h3>
        </div>
        <div className="space-y-3">
          <p className="pt-2 text-gray-800 text-sm lg:text-base dark:text-white">
            {product.actual_price} /{' '}
            <span className="text-xs opacity-70 sm:text-sm">{product.metric}</span>
          </p>
          <CartButton
            className="h-8 w-full text-xs sm:text-[13px]"
            image_url={product.image.src}
            quantity={1}
            {...product}
          />
        </div>
      </CardContent>
    </Card>
  );
}
