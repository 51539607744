import { ImageWithFallback } from '#/common/components/ImageWithFallback';
import type { GetSiteHome200InstagramItem } from '#/http/api/generated.schemas';

interface Props {
  posts: Array<GetSiteHome200InstagramItem>;
}

export function InstagramCards({ posts }: Props) {
  return (
    <div className="grid grid-cols-2 gap-4 overflow-hidden sm:grid-cols-4 2xl:gap-8">
      {posts.map(({ post_id, media_url, permalink }) => (
        <a
          key={post_id}
          href={permalink}
          target="_blank"
          rel="noreferrer"
          className="overflow-hidden rounded-md"
        >
          <ImageWithFallback
            className="aspect-square object-cover transition-all duration-300 hover:scale-110"
            src={media_url}
            alt={`Instagram post ${post_id}`}
            width={500}
            height={500}
          />
        </a>
      ))}
    </div>
  );
}
