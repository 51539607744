import Image, { type StaticImageData } from 'next/image';
import { useWindowSize } from 'usehooks-ts';
import { Icon } from '#/common/components/Icon';
import { AspectRatio } from '#/common/components/ui/aspect-ratio';
import { Button } from '#/common/components/ui/button';
import Banner_1 from '#/images/home/hero/1.png';
import Banner_1_mobile from '#/images/home/hero/1_mobile.png';
import Banner_2 from '#/images/home/hero/2.png';
import Banner_2_mobile from '#/images/home/hero/2_mobile.png';
import Banner_4 from '#/images/home/hero/4.png';
import Banner_4_mobile from '#/images/home/hero/4_mobile.png';
import Banner_5 from '#/images/home/hero/5.png';
import Banner_5_mobile from '#/images/home/hero/5_mobile.png';
import { useHeroCarousel } from '../hooks/useHeroCarousel';

interface Banner {
  alt: string;
  src: StaticImageData;
  src_mobile?: StaticImageData;
  href?: string;
}

const banners: Banner[] = [
  {
    alt: 'Jornada de Compras',
    src: Banner_1,
    src_mobile: Banner_1_mobile,
  },
  {
    alt: 'Tecidos para Decoração',
    src: Banner_2,
    src_mobile: Banner_2_mobile,
    href: '/catalogo?categories=1588',
  },
  {
    alt: 'Seda Pura',
    src: Banner_4,
    src_mobile: Banner_4_mobile,
    href: '/catalogo?categories=2360',
  },
  {
    alt: 'Crepes Estampados',
    src: Banner_5,
    src_mobile: Banner_5_mobile,
    href: '/catalogo?categories=1538',
  },
];

function BannerImage({
  image,
  isMobile,
  priority,
}: { image: Banner; isMobile: boolean; priority: boolean }) {
  return (
    <AspectRatio ratio={isMobile ? 1.65 : 1.78}>
      <Image
        width={1920}
        className="h-[260px] md:h-[360px] lg:h-[600px]"
        height={1080}
        priority={priority}
        {...image}
        src={isMobile && image.src_mobile ? image.src_mobile : image.src}
      />
    </AspectRatio>
  );
}

export function HeroCarousel() {
  const { emblaRef, scrollPrev, scrollNext } = useHeroCarousel();
  const { width } = useWindowSize();

  return (
    <section className="relative h-[260px] w-full overflow-hidden md:h-[360px] lg:h-[600px]">
      <div ref={emblaRef}>
        <div className="flex">
          {banners.map((image, index) => {
            const isFirstSlide = index === 0;
            const isMobile = width < 640;

            if (image.href) {
              return (
                <a
                  key={`${index}-${image.alt}`}
                  href={image.href}
                  className="min-w-0 flex-[0_0_100%]"
                >
                  <BannerImage
                    image={image}
                    isMobile={isMobile}
                    priority={isFirstSlide}
                  />
                </a>
              );
            }
            return (
              <div
                key={`${index}-${image.alt}`}
                className="min-w-0 flex-[0_0_100%]"
              >
                <BannerImage
                  image={image}
                  isMobile={isMobile}
                  priority={isFirstSlide}
                />
              </div>
            );
          })}
        </div>
      </div>
      <Button
        className="-translate-y-1/2 absolute top-1/2 left-2 hidden h-8 w-8 rounded-full md:flex"
        onClick={scrollPrev}
        variant="outline"
        size="icon"
      >
        <Icon
          name="arrow-left"
          className="h-4 w-4"
        />
        <span className="sr-only">Slide Anterior</span>
      </Button>
      <Button
        className="-translate-y-1/2 absolute top-1/2 right-2 hidden h-8 w-8 rounded-full md:flex"
        onClick={scrollNext}
        variant="outline"
        size="icon"
      >
        <Icon
          name="arrow-right"
          className="h-4 w-4"
        />
        <span className="sr-only">Slide Seguinte</span>
      </Button>
    </section>
  );
}
