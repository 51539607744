import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import CatalogLanzinhaRib from '#/assets/images/CatalogLanzinhaRib.jpeg';
import CatalogMicrosoft from '#/assets/images/CatalogMicrosoft.jpeg';
import CatalogPercal from '#/assets/images/CatalogPercal.jpeg';
import CatalogTecidoJacket from '#/assets/images/CatalogTecidoJacket.jpeg';
import EdicaoPrimaveraVerao from '#/assets/images/EdicaoPrimaveraVerao.png';
import { ScrollToTopButton } from '#/common/components/Button/ScrollToTopButton';
import { Carousel } from '#/common/components/Carousel/Carousel';
import { Catalogues } from '#/common/components/Catalogues';
import { Icon } from '#/common/components/Icon';
import { Separator } from '#/common/components/ui/separator';
import type { Images } from '#/common/types/images';
import { CardsSection } from '#/features/home/components/CardsSection';
import { GalleryCards } from '#/features/home/components/GalleryCards';
import { InstagramCards } from '#/features/home/components/InstagramCards';
import { useGetSiteHome } from '#/http/api/site/site';
import type { HomePageProps } from '#/pages/index.page';
import { HeroCarousel } from '../components/HeroCarousel';
import { InfoShipping } from '../components/InfoShipping';

const catalogs: Images = [
  {
    alt: 'Catálogo Tecido Jacket Matelasse',
    src: CatalogTecidoJacket,
    href: '/catalogo/produto/16176',
  },
  {
    alt: 'Catálogo Lanzinha Rib',
    src: CatalogLanzinhaRib,
    href: '/catalogo/produto/70778',
  },
  {
    alt: 'Catálogo Microsofts',
    src: CatalogMicrosoft,
    href: '/catalogo?categories=1576,1511',
  },
  {
    alt: 'Catálogo Percal Egípcio',
    src: CatalogPercal,
    href: '/catalogo?categories=1590,1591',
  },
];

export function HomePage(initialData: HomePageProps) {
  const {
    data: { instagram, promotional, recently, seasonal, trending },
  } = useGetSiteHome({
    query: {
      initialData,
    },
  });

  return (
    <>
      <VisuallyHidden.Root>
        <h1>Loja Virtual Entremalhas</h1>
      </VisuallyHidden.Root>
      <HeroCarousel />
      <section className="container py-4 pb-8">
        <div className="grid grid-cols-3 gap-4 text-center text-xs sm:text-sm">
          <InfoShipping
            icon="truck"
            title="Frete"
            legend={['Frete de R$ 15 a R$ 45 para regiões Sudeste e Sul']}
          >
            <p>
              Clientes Entremalhas podem se beneficiar de nossos valores exclusivos para{' '}
              <span className="font-bold">fretes em todo o país</span>.
            </p>
            <p>
              Regiões Sudeste e Sul possuem fretes com valores garantidos na faixa de R$
              15 a R$ 45.
            </p>
          </InfoShipping>

          <InfoShipping
            icon="credit-card"
            title="Condições de Parcelamento"
            legend={['Parcelamentos em até 5x sem juros']}
          >
            <p>
              Clientes podem parcelar os seus pedidos em até{' '}
              <span className="font-bold">5x no cartão</span> sem juros.
            </p>
            <p>
              Para optar basta selecionar a opção correspondente a sua preferência na
              conclusão do pedido.
            </p>
          </InfoShipping>
          <InfoShipping
            icon="user"
            title="Conclusão de Pedido"
            legend={['Conclusão de pedidos via WhatsApp']}
          >
            <p>
              Na Entremalhas operamos com{' '}
              <span className="font-bold">alta rotatividade</span> dos produtos.
            </p>
            <p>
              De forma a garantir a melhor experiência de compra, o seu pedido é repassado
              para uma vendedora que analisará o seu pedido e também irá resolver
              quaisquer dúvidas que você possa ter.
            </p>
            <p>
              Durante esse contato poderão haver maiores esclarecimentos sobre o seu
              pedido e informações cadastrais.
            </p>
          </InfoShipping>
        </div>
        {recently.length > 0 && (
          <CardsSection.Root>
            <CardsSection.Header
              title="Novidades"
              center
            />
            <Carousel
              label="Novidades"
              products={recently}
            />
            <CardsSection.Footer href="/catalogo">Ver Todos</CardsSection.Footer>
          </CardsSection.Root>
        )}
        {seasonal.length > 0 && (
          <>
            <Separator />
            <CardsSection.Root>
              <CardsSection.Header
                title="Confira nossa edição"
                center
              />
              <GalleryCards
                editionLink="/catalogo?categories=1524"
                editionImage={{
                  src: EdicaoPrimaveraVerao.src,
                  alt: 'Edição Primavera Verão',
                }}
                images={seasonal}
              />
              <CardsSection.Footer href="/catalogo?categories=1524">
                Confira
              </CardsSection.Footer>
            </CardsSection.Root>
          </>
        )}
        <Separator />
        {trending.length > 0 && (
          <CardsSection.Root>
            <CardsSection.Header
              title="Mais Buscados"
              center
            />
            <Carousel
              products={trending}
              label="Mais Buscados"
            />
            <CardsSection.Footer href="/catalogo">Ver Todos</CardsSection.Footer>
          </CardsSection.Root>
        )}
        {instagram.length > 0 && (
          <>
            <Separator />
            <CardsSection.Root>
              <CardsSection.Header title="Direto do Instagram">
                <p className="dark:text-gray-400">
                  Confira o que anda acontecendo por aqui!
                </p>
              </CardsSection.Header>
              <InstagramCards posts={instagram} />
              <CardsSection.Footer
                href="https://www.instagram.com/entremalhas"
                aria-label="Siga-nos no Instagram"
                target="_blank"
                rel="noreferrer"
              >
                <Icon
                  name="instagram"
                  size={24}
                />{' '}
                <span>SIGA-NOS NO INSTAGRAM</span>
              </CardsSection.Footer>
            </CardsSection.Root>
          </>
        )}
        <Separator />
        {promotional.length > 0 && (
          <CardsSection.Root>
            <CardsSection.Header
              title="Decoração"
              center
            />
            <Carousel
              products={promotional}
              label="Decoração"
            />
            <CardsSection.Footer href="/catalogo?categories=1588">
              Ver Todos
            </CardsSection.Footer>
          </CardsSection.Root>
        )}
        <Separator />
        <CardsSection.Root>
          <CardsSection.Header
            title="Confira Nossos Catálogos"
            center
          />
          <Catalogues images={catalogs} />
        </CardsSection.Root>
      </section>
      <ScrollToTopButton />
    </>
  );
}
