import type { InferGetStaticPropsType } from 'next';
import { HomePage } from '#/features/home/view/Template';
import { getSiteHome } from '#/http/api/site/site';

export async function getStaticProps() {
  try {
    const home = await getSiteHome();
    return {
      props: {
        ...home,
      },
      revalidate: 30, // Revalida a página a cada 30 segundos
    };
  } catch (error) {
    console.error(error);
  }
}

export type HomePageProps = InferGetStaticPropsType<typeof getStaticProps>;
export default HomePage;
